/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ReactComponent as BackIcon } from '../../../assets/icons/BackIcon.svg';
import { ReactComponent as LeftArrow } from '../../../assets/icons/ArrowIcon1.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/ArrowIcon2.svg';
import { ReactComponent as OpenArrow } from '../../../assets/components/Sidebar/openArrow.svg';
import ScoreCard from '../../../components/ScoreCard/scoreCard';
import Charty from '../../../components/charts/patient/ExerciseDetailsChart';
import ExerciseTable from '../../../components/tables/exerciseDetail/exerciseTable';
import ScreenShotViewer from '../../../components/ScreenShotViewer/index';
import { getMediaUrl, timezoneOffset } from '../../../config';
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import PainSurveyIcon from '../../../assets/icons/painSurveyIcon.svg';
import request from '../../../request';

function ExerciseDetails({ exercise, results, loading, breadCrumb, sessionID, activeTab }) {
  const [targetExercise, setTargetExercise] = useState({
    set: [],
    setCount: 1,
    rep: {},
    repCount: 1,
  });

  const [scores, setScores] = useState([]);
  const [toggleIcon, setToggleIcon] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedBodyPart, setSelectedBodyPart] = useState(null);
  const [minAngles, setMinAngles] = useState([]);
  const [maxAngles, setMaxAngles] = useState([]);
  const [exerciseDetails, setExerciseDetails] = useState({});
  const [thresholdLower, setThresholdLower] = useState(null);
  const [thresholdUpper, setThresholdUpper] = useState(null);

  const handleSetCounterAdd = () => {
    if (targetExercise.setCount < results.length) {
      setTargetExercise((prevExercise) => ({
        ...prevExercise,
        setCount: prevExercise.setCount + 1,
        repCount: 1, // Reset the rep counter to 1 when changing sets
        set: results[prevExercise.setCount], // Get the next set
        rep: results[prevExercise.setCount][0], // Set rep to the first rep in the new set
      }));
    }
  };
  console.log(exercise, 'qwerty');
  const getExerciseDetails = async () => {
    try {
      const res = await request('get', `/exercises/${exercise?.exercise}`);
      if (res) {
        setExerciseDetails(res || []); // Set state with the exemplar or an empty array if not found
      } else {
        setExerciseDetails([]); // Set state to empty array if direction or selectedPart is not defined
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  useEffect(() => {
    getExerciseDetails();
    // eslint-disable-next-line
  }, [exercise?.exercise]);

  useEffect(() => {
    const direction = targetExercise?.rep?.patient_facing; // e.g., 'left', 'right', or 'front'
    const selectedPart = selectedBodyPart; // e.g., 'hip_angle', 'shoulder_angle'
    if (direction && selectedPart && exerciseDetails) {
      // Construct the dynamic path using direction and angle
      const exemplar =
        exerciseDetails?.data?.exercise?.angles?.[direction]?.[
          selectedPart === 'left_spine_angle' || selectedPart === 'right_spine_angle'
            ? 'spine_angle'
            : selectedPart
        ]?.exemplar;
      setThresholdLower(exemplar?.length > 0 ? Math.min(...exemplar) : null);
      setThresholdUpper(exemplar?.length > 0 ? Math.max(...exemplar) : null);
    } else {
      setThresholdLower(null);
      setThresholdUpper(null);
    }
  }, [exerciseDetails, targetExercise?.rep?.patient_facing, selectedBodyPart]);
  useEffect(() => {
    if (selectedBodyPart && results?.length > 0) {
      const selectedSetIndex = targetExercise.setCount - 1; // Get the index for the selected set
      const selectedSet = results[selectedSetIndex]; // Select the specific set

      if (selectedSet) {
        const minPoints = [];
        const maxPoints = [];

        selectedSet?.forEach((rep) => {
          const jointAngles = rep?.angles[selectedBodyPart];
          if (!jointAngles) return;
          const allAngles = [...jointAngles[0], ...jointAngles[1]];

          minPoints?.push(Math.min(...allAngles));
          maxPoints?.push(Math.max(...allAngles));
        });

        setMinAngles(minPoints);
        setMaxAngles(maxPoints);
      }
    }
  }, [selectedBodyPart, results, targetExercise.setCount]);

  // useEffect(() => {
  //   if (targetExercise) {
  //     const vall = targetExercise?.rep?.angle_scores
  //       ? Object.keys(targetExercise?.rep.angle_scores)[0]
  //       : null;
  //     setSelectedBodyPart(vall);
  //   }
  //   console.log(targetExercise, 'targetExercise');
  // }, []);

  useEffect(() => {
    setTargetExercise((prevExercise) => ({
      ...prevExercise,
      setCount: 1,
      repCount: 1,
      set: [],
      rep: {},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  // useEffect(() => {
  //   const temp = Array.isArray(results) ? results.flat() : [];
  //   const angleSelected = selectedBodyPart;

  //   const newExtractedValues = [];
  //   temp.forEach((result) => {
  //     if (result.angle_scores) {
  //       const leftAngles = {};
  //       Object.keys(result.angle_scores).forEach((key\) => {
  //         if (key === angleSelected) {
  //           leftAngles[key] = result.angle_scores[key];
  //         }
  //       });
  //       newExtractedValues.push(leftAngles);
  //     }
  //   });

  //   setExtractedValues(newExtractedValues);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [results]);
  // --------------------------------------DONT DELETE THIS CODE----------------------------------
  // useEffect(() => {
  //   // Extract and store the values from extractedValues
  //   const newValuesArray = extractedValues?.map((item) => Object.values(item)).flat();
  //   setGraphValues(newValuesArray);
  // }, [extractedValues]);
  const handleRepCounterAdd = () => {
    if (targetExercise?.repCount < targetExercise.set.length) {
      setTargetExercise((prevExercise) => ({
        ...prevExercise,
        repCount: prevExercise.repCount + 1,
        rep: prevExercise.set[prevExercise.repCount], // Get the next rep in the current set
      }));
    } else {
      // If we reach the last rep in the current set, move to the next set
      handleSetCounterAdd();
    }
  };

  const handleSetCounterSubtract = () => {
    if (targetExercise?.setCount > 1) {
      setTargetExercise((prevExercise) => ({
        ...prevExercise,
        setCount: prevExercise.setCount - 1,
        repCount: 1, // Reset the rep counter to 1 when changing sets
        set: results[prevExercise.setCount - 2], // Get the previous set
        rep: results[prevExercise.setCount - 2][0], // Set rep to the first rep in the previous set
      }));
    }
  };

  const handleRepCounterSubtract = () => {
    if (targetExercise?.repCount > 1) {
      setTargetExercise((prevExercise) => ({
        ...prevExercise,
        repCount: prevExercise.repCount - 1,
        rep: prevExercise.set[prevExercise.repCount - 2], // Get the previous rep in the current set
      }));
    } else {
      // If we are at the first rep of the current set, move to the previous set
      // eslint-disable-next-line no-lonely-if
      if (targetExercise.setCount > 1) {
        setTargetExercise((prevExercise) => ({
          ...prevExercise,
          setCount: prevExercise.setCount - 1,
          repCount: results[prevExercise.setCount - 2]?.length, // Set rep to the last rep in the previous set
          set: results[prevExercise.setCount - 2], // Get the previous set
          rep: results[prevExercise.setCount - 2][results[prevExercise.setCount - 2].length - 1], // Set rep to the last rep in the previous set
        }));
      }
    }
  };

  const jointNames = (bodyPart) => {
    const formattedName =
      bodyPart === 'left_spine_angle' || bodyPart === 'right_spine_angle'
        ? bodyPart
            .split('_')
            .filter((word) => word !== 'angle' && word !== 'left' && word !== 'right')
            .map((word, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${word}-${index}`}>
                {index > 0 && ' '}
                {word.charAt(0).toUpperCase() + word.slice(1)}
              </React.Fragment>
            ))
        : bodyPart
            .split('_')
            .filter((word) => word !== 'angle')
            .map((word, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${word}-${index}`}>
                {index > 0 && ' '}
                {word.charAt(0).toUpperCase() + word.slice(1)}
              </React.Fragment>
            ));

    return formattedName;
  };

  const handleSelectedImage = (index) => {
    setSelectedImageIndex(index);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      prevIndex === exercise?.screenshots?.length - 1
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          exercise?.screenshots.length - 1
        : prevIndex + 1,
    );
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  function capitalizeFirstLetterOfEachWord(inputString) {
    const words = inputString?.split(' ');
    const capitalizedWords = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords?.join(' ');
  }

  useEffect(() => {
    if (results && results.length > 0) {
      setTargetExercise((prevExercise) => ({
        ...prevExercise,
        set: results[0],
        rep: results[0][0],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const painScaleBoxColors = [
    'rgba(81, 170, 132, 1)',
    'rgba(100, 200, 97, 1)',
    'rgba(191, 201, 55, 1)',
    'rgba(241, 227, 50, 1)',
    'rgba(253, 216, 49, 1)',
    'rgba(253, 187, 44, 1)',
    'rgba(253, 144, 37, 1)',
    'rgba(252, 102, 34, 1)',
    'rgba(219, 44, 24, 1)',
    'rgba(184, 54, 51, 1)',
  ];
  const percentageOfGraph =
    targetExercise?.rep?.screnr_performance > 0 && targetExercise?.rep?.screnr_performance <= 10
      ? '#FF5151'
      : targetExercise?.rep?.screnr_performance > 10 &&
        targetExercise?.rep?.screnr_performance <= 30
      ? '#C2623B'
      : targetExercise?.rep?.screnr_performance > 30 &&
        targetExercise?.rep?.screnr_performance <= 40
      ? '#D0A34D'
      : targetExercise?.rep?.screnr_performance > 40 &&
        targetExercise?.rep?.screnr_performance <= 50
      ? '#E7D270'
      : targetExercise?.rep?.screnr_performance > 50 &&
        targetExercise?.rep?.screnr_performance <= 55
      ? '#F0E570'
      : targetExercise?.rep?.screnr_performance > 55 &&
        targetExercise?.rep?.screnr_performance <= 65
      ? '#E0EE66'
      : targetExercise?.rep?.screnr_performance > 65 &&
        targetExercise?.rep?.screnr_performance <= 70
      ? '#CAE662'
      : targetExercise?.rep?.screnr_performance > 70 &&
        targetExercise?.rep?.screnr_performance <= 75
      ? '#A9D55D'
      : targetExercise?.rep?.screnr_performance > 75 &&
        targetExercise?.rep?.screnr_performance <= 80
      ? '#AED05D'
      : targetExercise?.rep?.screnr_performance > 80 &&
        targetExercise?.rep?.screnr_performance <= 85
      ? '#97C755'
      : targetExercise?.rep?.screnr_performance > 85 &&
        targetExercise?.rep?.screnr_performance <= 90
      ? '#7EB252'
      : targetExercise?.rep?.screnr_performance > 90 &&
        targetExercise?.rep?.screnr_performance <= 95
      ? '#7EB45A'
      : '#669851';

  const handleScoreFromData = () => {
    const tempArray = [];
    // eslint-disable-next-line no-unused-expressions
    targetExercise?.rep?.angle_scores &&
      Object?.entries(targetExercise?.rep?.angle_scores)?.map(([key, value], index) =>
        tempArray.push({ name: [key], score: value, id: index + 1 }),
      );
    setScores(tempArray);
  };

  useEffect(() => {
    handleScoreFromData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetExercise]);

  return selectedImageIndex !== null ? (
    <ScreenShotViewer
      screenshots={exercise?.screenshots}
      selectedImageIndex={selectedImageIndex}
      handleClose={handleClose}
      handleNextImage={handleNextImage}
      handlePrevImage={handlePrevImage}
    />
  ) : (
    <div
      className="bg_main_div_sessions"
      style={{ background: '#F6FAFB', paddingBottom: '40px', height: '100%', minHeight: '100vh' }}
    >
      <Breadcrumbs ClinicName={breadCrumb} sessionID={sessionID} activeTab={activeTab} />
      {!exercise?.type && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <div style={{ height: 'inherit' }} className="progressContainer">
            <CircularProgress className="BlueCircularProgress" size={50} />
          </div>
        </div>
      )}
      {exercise?.type === 'motion' && (
        <>
          <div className="bgAddPrescriptionSessions">
            <div className="SessionsHeading">
              <div className="leftside_title">
                <div className="backIcon_button">
                  <BackIcon
                    className="arrows"
                    onClick={() => {
                      window.history.back();
                    }}
                  />
                </div>
                <div className="dateTitle">
                  <p>Date</p>
                </div>
                <div className="dateExcercise">
                  <p>
                    {moment(exercise?.completedAt)
                      .utc()
                      .utcOffset(timezoneOffset)
                      .format('MM/DD/YYYY')}
                  </p>
                </div>
              </div>
              <div className="excerciseDay">{/* <p>{session?.name}</p> */}</div>
            </div>

            <div className="excercise_name_heading">
              <div
                className="excerciseNameIcon"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* <TitleExcerciseIcon /> */}
                <p className="excercise_name_p">
                  {capitalizeFirstLetterOfEachWord(exercise?.name)}
                </p>
              </div>
            </div>
            <div className="excercise_details">
              <div className="setNumber">
                <div className="text_rep_set">
                  <p>Set</p>
                </div>
                <div className="set_rep_valueNumber">
                  <LeftArrow
                    style={{ cursor: targetExercise?.set?.length ? 'pointer' : 'no-drop' }}
                    onClick={handleSetCounterSubtract}
                  />
                  <p>{targetExercise?.setCount}</p>
                  <RightArrow
                    style={{ cursor: targetExercise?.set?.length ? 'pointer' : 'no-drop' }}
                    onClick={handleSetCounterAdd}
                  />
                </div>
              </div>
              <div className="setNumber">
                <div className="text_rep_set">
                  <p>Repetition</p>
                </div>
                <div className="set_rep_valueNumber">
                  <LeftArrow
                    style={{ cursor: targetExercise?.set?.length ? 'pointer' : 'no-drop' }}
                    onClick={handleRepCounterSubtract}
                  />
                  <p>{targetExercise?.repCount}</p>
                  <RightArrow
                    style={{ cursor: targetExercise?.set?.length ? 'pointer' : 'no-drop' }}
                    onClick={handleRepCounterAdd}
                  />
                </div>
              </div>
            </div>
            <div className="jointScoreHeading">
              {targetExercise?.rep?.patient_facing === 'front' ? (
                <p
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  Joint Score ({targetExercise?.rep?.patient_facing} View)
                </p>
              ) : (
                <p
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  Joint Score ({targetExercise?.rep?.patient_facing} Lateral View)
                </p>
              )}
            </div>
            <div className="jointScoreOuterDiv">
              <div className="score_Bar1">
                <div className="overallScoreGraph">
                  <div className="overAll_Arrows">
                    {/* <LeftArrow className="arrows" /> */}
                    <p className="excercises_names">Performance</p>
                    {/* <RightArrow className="arrows" /> */}
                  </div>
                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress
                      variant="determinate"
                      thickness={6}
                      value={Number(targetExercise?.rep?.screnr_performance) || 0}
                      size={80}
                      sx={{
                        color: percentageOfGraph,
                      }}
                    />

                    <div className="graphData">
                      <p className="excercise_percent">
                        {targetExercise?.rep?.screnr_performance
                          ? Math.round(targetExercise?.rep?.screnr_performance)
                          : 0}
                        %
                      </p>
                    </div>
                  </div>

                  {/* <div className="colorDotsDiv2">
      <div className="colorDots">
        <RedDot />
        <p className="colortxt">Low</p>
      </div>
      <div className="colorDots">
        <GreenDot />
        <p className="colortxt">Medium</p>
      </div>
      <div className="colorDots">
        <YellowDot />
        <p className="colortxt">High</p>
      </div>
    </div> */}
                </div>
              </div>
              {scores?.map((item) => (
                <ScoreCard
                  bodyPart={jointNames(item?.name[0])}
                  orignalBodyPartName={item?.name[0]}
                  firstvalue={scores[0]}
                  selectedBodyPart={selectedBodyPart}
                  scoreValue={item?.score}
                  key={item?.id}
                  setAngleName={setSelectedBodyPart}
                />
              ))}
            </div>
          </div>
          <div className="chartWrapOnExerciseDetailsScreen">
            <Charty
              jointAngleData1={maxAngles}
              jointAngleData2={minAngles}
              thresholdUpper={thresholdUpper}
              thresholdLower={thresholdLower}
              exerciseName={selectedBodyPart}
              targetExercise={targetExercise}
            />
          </div>
          <div
            className="screenShotDiv"
            onClick={() => setToggleIcon((prev) => !prev)}
            style={{ display: exercise?.type === 'motion' ? 'flex' : 'none' }}
          >
            <div className="screenShotTitleDiv">
              <span>Screen Shots</span>
              {toggleIcon ? (
                <OpenArrow
                  style={{
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <OpenArrow
                  style={{
                    transform: 'rotateX(180deg)',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          </div>

          <Collapse in={toggleIcon} timeout="auto" className="screenShotsPhotoCollapse">
            <div className="screenShotImagesDiv">
              {exercise?.screenshots?.length > 0 ? (
                exercise?.screenshots?.map((item, index) => {
                  const imageOrientation = item.split('_')[4].split('.')[0];
                  return (
                    <img
                      src={getMediaUrl(item)}
                      key={item}
                      alt={`screenShotImages${index + 1}`}
                      className="screenShotImages"
                      style={{
                        transform:
                          imageOrientation === 'portrait'
                            ? 'scaleX(-1)'
                            : 'rotate(90deg) scaleX(-1)',
                        filter: 'FlipH',
                        width: imageOrientation === 'portrait' ? '8rem' : '8rem',
                        height: imageOrientation === 'portrait' ? '6rem' : '8rem',
                      }}
                      onClick={() => {
                        handleSelectedImage(index);
                      }}
                    />
                  );
                })
              ) : (
                <span className="noDataTxt">No Screen Shots</span>
              )}
            </div>
          </Collapse>
          <div className="performanceGraphDiv">
            <ExerciseTable feedbacks={targetExercise?.rep?.feedback_details} loading={loading} />
          </div>
        </>
      )}

      {exercise?.type === 'simple' && (
        <div>
          <div className="bgAddPrescriptionSessionsSimple">
            <div className="SessionsHeading">
              <div className="leftside_title">
                <div className="backIcon_button">
                  <BackIcon
                    className="arrows"
                    onClick={() => {
                      window.history.back();
                    }}
                  />
                </div>
                <div className="dateTitle">
                  <p>Session Date</p>
                </div>
                <div className="dateExcercise">
                  {/* {exercise?.status === 'completed' && exercise?.completedAt
                    ? moment(exercise?.completedAt).utc().utcOffset(-7).format('MM/DD/YYYY')
                    : 'N/A'} */}
                  {/* QA-Sohail asked to show date even status is not completed */}
                  {moment(exercise?.completedAt)
                    .utc()
                    .utcOffset(timezoneOffset)
                    .format('MM/DD/YYYY')}
                </div>
              </div>
              <div className="excerciseDay">{capitalizeFirstLetterOfEachWord(exercise?.name)}</div>
            </div>

            <div className="excercise_name_heading">
              <div
                className="excerciseNameIcon"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* <TitleExcerciseIcon /> */}
              </div>
            </div>
          </div>
          <div className="simpleExcerciseColorsParent">
            <div className="colorsDivSimpleExcer">
              <p className="simpleExcerTitles">
                Pain <span className="yellowTxt">Before</span> Exercise
              </p>
              <div className="painScaleSimple">
                {painScaleBoxColors?.map((color, index) => {
                  return (
                    <div style={{ width: '100%' }} key={color}>
                      <div
                        className="painScaleColorDiv"
                        style={{
                          backgroundColor: color,
                          height: '82%',
                          borderTopLeftRadius: index === 0 ? '10px' : '',
                          borderBottomLeftRadius: index === 0 ? '10px' : '',
                          borderTopRightRadius:
                            index === painScaleBoxColors.length - 1 ? '10px' : '',
                          borderBottomRightRadius:
                            index === painScaleBoxColors.length - 1 ? '10px' : '',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        key={color}
                      >
                        <img
                          src={PainSurveyIcon}
                          style={{
                            display:
                              // eslint-disable-next-line eqeqeq
                              exercise?.survey?.[0]?.answer == index ? 'inline-block' : 'none',
                            paddingInline: '1px',
                            width: '95%',
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          // eslint-disable-next-line eqeqeq
                          color: exercise?.survey?.[0].answer == index ? color : '',
                          fontSize: '11px',
                          marginTop: '3px',
                        }}
                      >
                        {index}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="colorsDivSimpleExcer">
              <p className="simpleExcerTitles">
                Pain <span className="yellowTxt">After</span> Exercise
              </p>
              <div className="painScaleSimple">
                {painScaleBoxColors?.map((color, index) => {
                  return (
                    <div style={{ width: '100%' }} key={color}>
                      <div
                        className="painScaleColorDiv"
                        style={{
                          backgroundColor: color,
                          height: '82%',
                          borderTopLeftRadius: index === 0 ? '10px' : '',
                          borderBottomLeftRadius: index === 0 ? '10px' : '',
                          borderTopRightRadius:
                            index === painScaleBoxColors.length - 1 ? '10px' : '',
                          borderBottomRightRadius:
                            index === painScaleBoxColors.length - 1 ? '10px' : '',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        key={color}
                      >
                        <img
                          src={PainSurveyIcon}
                          style={{
                            display:
                              exercise?.survey?.[1]?.answer == index ? 'inline-block' : 'none',
                            paddingInline: '1px',
                            width: '95%',
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          color: exercise?.survey?.[1]?.answer == index ? color : '',
                          fontSize: '11px',
                          marginTop: '3px',
                        }}
                      >
                        {index}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="colorsDivSimpleExcer">
              <p className="simpleExcerTitles">Difficulty</p>
              <div className="painScaleSimple">
                {painScaleBoxColors?.map((color, index) => {
                  return (
                    <div style={{ width: '100%' }} key={color}>
                      <div
                        className="painScaleColorDiv"
                        style={{
                          backgroundColor: color,
                          height: '82%',
                          borderTopLeftRadius: index === 0 ? '10px' : '',
                          borderBottomLeftRadius: index === 0 ? '10px' : '',
                          borderTopRightRadius:
                            index === painScaleBoxColors.length - 1 ? '10px' : '',
                          borderBottomRightRadius:
                            index === painScaleBoxColors.length - 1 ? '10px' : '',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        key={color}
                      >
                        <img
                          src={PainSurveyIcon}
                          style={{
                            display:
                              exercise?.survey?.[2]?.answer == index ? 'inline-block' : 'none',
                            paddingInline: '1px',
                            width: '95%',
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          color: exercise?.survey?.[2]?.answer == index ? color : '',
                          fontSize: '11px',
                          marginTop: '3px',
                        }}
                      >
                        {index}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="excerciseCompletedQstn">
            <p className="qstnTxtSimpleExcer">Did you complete the exercise ?</p>
            <p className="answerSimpleExcerTxt">
              {exercise?.simple_results_incomplete
                ? 'No'
                : Object.keys(exercise).includes('patient_exercise_feedback')
                ? 'Yes'
                : 'N/A'}
            </p>
          </div>
          {exercise?.status == 'completed' && (
            <div className="excerciseCompletedQstn">
              <p className="qstnTxtSimpleExcer">
                {exercise?.simple_results_incomplete
                  ? 'Why were you not able to complete your exercise?'
                  : 'Which part of the exercise did you find most challenging?'}
              </p>

              {exercise?.simple_results_incomplete ? (
                exercise?.simple_results_incomplete?.reason?.map((result, index) => (
                  <p key={index.id} className="answerSimpleExcerTxtTwo">
                    {index + 1}. {result}.
                  </p>
                ))
              ) : Object.keys(exercise).includes('patient_exercise_feedback') ? (
                <p className="answerSimpleExcerTxtTwo">
                  {exercise?.patient_exercise_feedback || 'no response found'}
                </p>
              ) : null}
            </div>
          )}

          {exercise?.status !== 'completed' && (
            <div className="excerciseCompletedQstnNo">
              <p className="qstnTxtSimpleExcerNo">How many sets and reps were completed?</p>
              <div className="answerSimpleExcerTxtTwoNo">
                <p className="setsRepsSimple">Sets</p>
                <p className="setsRepsSimpleVal">
                  <span className="BlueTxtVal">
                    {exercise?.simple_results_incomplete?.completed_set}
                  </span>{' '}
                  / {exercise?.sets || '0'}
                </p>
              </div>
              <div className="answerSimpleExcerTxtTwoNo">
                <p className="setsRepsSimple">Reps</p>
                <p className="setsRepsSimpleVal">
                  <span className="BlueTxtVal">
                    {exercise?.simple_results_incomplete?.completed_reps}
                  </span>{' '}
                  / {exercise?.reps || '0'}
                </p>
              </div>
            </div>
          )}
          {exercise?.status !== 'completed' && (
            <div className="excerciseCompletedQstn">
              <p className="qstnTxtSimpleExcer">Why not completed ?</p>
              {exercise?.simple_results_incomplete?.reason?.map((item) => (
                <p className="answerSimpleExcerTxtTwo">{item}</p>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ExerciseDetails;
